<template lang="pug">
footer
  .footer
    .footer-category
      .footer-category-content
        .ttl
          | ヘルプ・お問い合わせ
        ul
          li
            a.external_link#footer_ads(:href="`${$urls.vook}/inquiry/ads`")
              | 広告掲載について
          li
            a.external_link#footer_contact(:href="`${$urls.vook}/inquiry/new`")
              | お問い合わせ
          li
            a.external_link#footer_support(:href="`${$urls.vook}/support`")
              | よくある質問
      .footer-category-content
        .ttl
          | 利用規約
        ul
          li
            a.external_link#footer_terms(:href="`${$urls.vook}/pages/terms`")
              | 利用規約
          li
            a.external_link#footer_privacy(:href="`${$urls.vook}/pages/privacy`")
              | プライバシーポリシー
          li
            a.external_link#footer_law(:href="`${$urls.vook}/pages/law`")
              | 特定商取引法の表示
      .footer-category-content
        .ttl
          | 他サービス
        ul
          li
            a.external_link#footer_career(:href="$urls.career" target="_blank")
              | Vookキャリア
          li
            a.external_link#footer_school(href="/" target="_blank")
              | Vook school
      .footer-category-content
        .ttl
          | 運営会社
        ul
          li
            a.external_link#footer_corporate(href="https://vook.co.jp/" target="_blank")
              | 会社概要
          li
            a.external_link#footer_recruit(href="https://www.notion.so/b9abe2bdf4f14dc39a8b5077ce1be29d" target="_blank")
              | 採用情報
    .flex
      .footer-logo
        a.external_link#footer_logo(href="/")
          icon-logo-vook-svg
        address
          | &copy;
          | {{ nowYear() }}
          | &nbsp;Vook
          | .
      .footer-sns
        ul
          li
            a.external_link#footer_twitter(href="https://mobile.twitter.com/VookJp" target="_blank")
              img(v-lazy="require(`~/assets/images/svg/sns_twitter.svg`)" width="20" height="16" alt="X(Twitter) @VookJp")
          li
            a.external_link#footer_facebook(href="https://www.facebook.com/VookJp/" target="_blank")
              img(v-lazy="require(`~/assets/images/svg/sns_facebook.svg`)" width="21" height="21" alt="Facebook Vook")
          li
            a.external_link#footer_youtube(href="https://www.youtube.com/channel/UCwQ0VT8WWOT5Nvcg5Q43zxA" target="_blank")
              img(v-lazy="require(`~/assets/images/svg/sns_youtube.svg`)" width="21" height="15" alt="Vook YouTubeチャンネル")

</template>

<script>
import IconLogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
export default {
  components: {
    IconLogoVookSvg
  },
  methods: {
    nowYear() {
      const now = new Date()
      return now.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
footer {
  position: relative;
  border-top: 1px solid $light_violet_border;
  background: #fff;
  .footer {
    max-width: $min_width;
    margin: 0 auto;
    @include media(pc) {
      padding: 55px 0 5px;
    }
    @include media(sp) {
      padding-top: 20px;
    }
    &-category {
      border-bottom: 1px solid $light_violet_border;
      @include media(pc) {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
      }
      &-content {
        flex: 1;
        @include media(sp) {
          margin: 0 10px;
          padding: 15px 0;
        }
        .ttl {
          font-size: 16px;
          font-weight: bold;
          padding: 0 10px;
          margin-bottom: 15px;
          @include media(sp) {
            margin-bottom: 10px;
          }
        }
        ul {
          li {
            a {
              display: inline-block;
              font-size: 14px;
              padding: 8px 10px;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          li + li {
            margin-top: 3px;
          }
        }
        & + .footer-category-content {
          @include media(sp) {
            border-top: 1px solid $light_violet_border;
          }
        }
      }
    }
    .category {
      @include media(sp) {
        padding: 20px 10px 10px;
        li {
          a {
            display: block;
            font-size: 14px;
            padding: 5px 0;
            text-decoration: none;
          }
        }
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 5px;
      @include media(sp) {
        padding: 20px;
      }
    }
    &-logo {
      display: flex;
      align-items: flex-end;
      a {
        display: block;
        svg {
          @include media(pc) {
            width: 80px;
            height: 28px;
          }
          @include media(sp) {
            width: 58px;
            height: 20px;
          }
        }
      }
      address {
        font-style: unset;
        @include media(pc) {
          margin-left: 20px;
        }
        @include media(sp) {
          display: block;
          margin-top: 10px;
          white-space: nowrap;
          font-size: 11px;
        }
      }
    }
    &-sns {
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        li + li {
          margin-left: 25px;
          @include media(sp) {
            margin-left: 15px;
          }
        }
        img {
          width: 30px;
          opacity: 0.5;
          transition: all 0.2s;
          @include media(sp) {
            width: 28px;
          }
        }
        a:hover {
          img {
            opacity: 0.3;
          }
        }
      }
    }
  }
}
</style>
