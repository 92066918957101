<template lang="pug">
div(@click="$store.commit('layout/init')")
  service-header
  SimpleHeader
  header-email-confirmation
  flash-message
  email-unregistered-warning
  nuxt
  Footer
  proxy-login-ribbon
</template>

<script>
import ServiceHeader from '~/components/ServiceHeader'
import SimpleHeader from '~/components/SimpleHeader'
import HeaderEmailConfirmation from '~/components/HeaderEmailConfirmation.vue'
import FlashMessage from '~/components/FlashMessage'
import EmailUnregisteredWarning from '~/components/EmailUnregisteredWarning'
import Footer from '~/components/Footer'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'

export default {
  components: {
    ServiceHeader,
    SimpleHeader,
    HeaderEmailConfirmation,
    EmailUnregisteredWarning,
    FlashMessage,
    Footer,
    ProxyLoginRibbon
  }
}
</script>
