<template lang="pug">
footer.l-footer
  .l-container
    ul.footer-left
      li
        address
          | &copy;
          | {{ nowYear() }}
          | &nbsp;
          a(:href="$urls.vook") Vook
          | .
      li
        nuxt-link(to="/") Vook school TOP
    ul.footer-right
      li
        a(:href="`${$urls.vook}/inquiry/new`")
          | お問い合わせ
      li
        nuxt-link(to="/terms")
          | Vook school 利用規約
      li(v-show="$route.path === '/registration'")
        nuxt-link(to="/law")
          | Vook school 特定商取引法の表示
</template>

<script>
export default {
  methods: {
    nowYear() {
      const now = new Date()
      return now.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
.l-footer {
  position: relative;
  z-index: 1; //-モーショングラフィックスティザーサイト用に指定
  padding: 20px 0;
  font-size: 12px;
  color: rgba($dark_violet, 0.6);
  width: 100%;
  border-top: 1px solid #eee;
  background: $light_gray;
  @include media(sp) {
    padding: 20px 0 68px;
  }
  .l-container {
    overflow: hidden;
  }
  .footer-left {
    float: left;
  }
  .footer-right {
    float: right;
  }
  li {
    display: inline-block;
    margin-right: 20px;
  }
  address {
    font-style: normal;
    padding-right: 20px;
    border-right: 1px solid #dadada;
  }
  a {
    text-decoration: none;
    color: rgba($dark_violet, 0.6);
    transition: all 0.2s;
  }
  a:hover {
    color: rgba($dark_violet, 1);
  }
}
@include media(sp) {
  .l-footer {
    font-size: 11px;
    position: relative;
    z-index: 11; //fixed bottom:0の要素より上にくるように
    .footer-left {
      float: none;
      margin-bottom: 8px;
    }
    .footer-right {
      float: none;
    }
    a {
      padding: 5px 0;
    }
  }
}
</style>
