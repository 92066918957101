import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.locale('ja')
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)

type Dayjs = string | number | Date | dayjs.Dayjs

export function getDateString(date?: Dayjs): string {
  return dayjs(date).format('YYYY.MM.DD')
}

export function getDateStringJa(date?: Dayjs): string {
  return dayjs(date).format('YYYY年M月D日(dd)')
}

export function getDateShortStringJa(date?: Dayjs): string {
  return dayjs(date).format('M月D日(dd)')
}

export function getBirthdateStringJa(date: Dayjs): string {
  return dayjs(date).format('YYYY年M月D日')
}

export function getDateTimeStringJa(date?: Dayjs): string {
  return dayjs(date).format('YYYY年M月D日(dd) H:mm')
}

export function getPeriodStringJa(beginAt: Dayjs, endAt: Dayjs): string {
  const b = dayjs(beginAt)
  const e = dayjs(endAt)
  const bs = getDateTimeStringJa(b)
  if (b.format('YYYYMMDD') === e.format('YYYYMMDD')) {
    return `${bs} - ${e.format('H:mm')}`
  } else {
    return `${bs} - ${e.format('M月D日(dd) H:mm')}`
  }
}

export function isValidDate(date: string, format: string): boolean {
  // NOTE: https://qiita.com/kubotak/items/3bbde3c976606493060d
  return dayjs(date).format(format) === date
}

export function isPeriod(
  startDate: string,
  endDate: string,
  today?: string
): boolean {
  return dayjs(today).isBetween(startDate, endDate)
}

export function beforeIt(setTime: string): boolean {
  return dayjs().isSameOrBefore(dayjs(setTime))
}

export default dayjs
