<template lang="pug">
header.simple-header
  a(:href="$urls.vook")
    logo-vook-svg
      title Vook
</template>
<script>
import LogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'

export default {
  components: {
    LogoVookSvg
  }
}
</script>
<style lang="scss" scoped>
.simple-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  background: #fff;
  height: $simple_header_height;
  line-height: 0;
  svg {
    width: 60px;
    height: 21px;
    fill: #000;
  }
}
</style>
