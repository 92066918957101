<template lang="pug">
div(@click="$store.commit('layout/init')")
  service-header
  flash-message
  nuxt
  school-footer
</template>

<script>
import ServiceHeader from '~/components/ServiceHeader'
import FlashMessage from '~/components/FlashMessage'
import SchoolFooter from '~/components/school/Footer'

export default {
  components: {
    ServiceHeader,
    FlashMessage,
    SchoolFooter
  }
}
</script>
