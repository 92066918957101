const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['not_authenticated'] = require('../middleware/not_authenticated.js')
middleware['not_authenticated'] = middleware['not_authenticated'].default || middleware['not_authenticated']

middleware['not_school_student'] = require('../middleware/not_school_student.ts')
middleware['not_school_student'] = middleware['not_school_student'].default || middleware['not_school_student']

middleware['only_organized_school'] = require('../middleware/only_organized_school.ts')
middleware['only_organized_school'] = middleware['only_organized_school'].default || middleware['only_organized_school']

middleware['only_organized_vp'] = require('../middleware/only_organized_vp.ts')
middleware['only_organized_vp'] = middleware['only_organized_vp'].default || middleware['only_organized_vp']

middleware['only_school_camp'] = require('../middleware/only_school_camp.ts')
middleware['only_school_camp'] = middleware['only_school_camp'].default || middleware['only_school_camp']

middleware['reject_direct_access'] = require('../middleware/reject_direct_access.ts')
middleware['reject_direct_access'] = middleware['reject_direct_access'].default || middleware['reject_direct_access']

middleware['remove_trailing_slash'] = require('../middleware/remove_trailing_slash.ts')
middleware['remove_trailing_slash'] = middleware['remove_trailing_slash'].default || middleware['remove_trailing_slash']

export default middleware
