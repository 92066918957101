<template lang="pug">
div(@click="$store.commit('layout/init')")
  flash-message
  .l-school_form
    header.school_form-header
      nuxt-link(to="/")
        img(src="~/assets/images/school/common/logo.svg" width="152" height="41")
    Nuxt
    school-footer

</template>

<script>
import FlashMessage from '~/components/FlashMessage'
import SchoolFooter from '~/components/school/Footer.vue'

export default {
  components: {
    FlashMessage,
    SchoolFooter
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .l-school_form {
    .school_form-header {
      padding: 15px 0;
      background: #fff;
      text-align: center;
      border-bottom: 1px solid #eee;
      img {
        @include media(pc) {
          width: 152px;
          height: 41px;
        }
        @include media(sp) {
          width: 112px;
          height: 30px;
        }
      }
    }
  }
}
</style>
