import { Middleware } from '@nuxt/types'

const middleware: Middleware = ({ $auth, app, redirect }) => {
  // 仮登録or在校or休校の学生はアクセス不可
  const profile = $auth.user?.school_profile as schools.Profile
  if (profile) {
    const isStudent = [
      'video_grapher',
      'motion_graphics',
      'video_grapher_onlne'
    ].includes(profile.course)

    const isActive = [
      'temporary',
      'in_school',
      'suspension',
      'unpaid'
    ].includes(profile.status)

    if (isStudent && isActive) {
      app.$cookies.set(
        'flashAlertMessage',
        '複数のコースに申し込みはできません'
      )
      redirect('/')
    }
  }
}

export default middleware
