import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60bffa11 = () => interopDefault(import('../pages/billing/index.vue' /* webpackChunkName: "pages/billing/index" */))
const _0f63d836 = () => interopDefault(import('../pages/complete.vue' /* webpackChunkName: "pages/complete" */))
const _69ef3005 = () => interopDefault(import('../pages/download.vue' /* webpackChunkName: "pages/download" */))
const _19768196 = () => interopDefault(import('../pages/law.vue' /* webpackChunkName: "pages/law" */))
const _f1748ad0 = () => interopDefault(import('../pages/motion-graphics/index.vue' /* webpackChunkName: "pages/motion-graphics/index" */))
const _039f91fb = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _e7fac168 = () => interopDefault(import('../pages/online_courses/index.vue' /* webpackChunkName: "pages/online_courses/index" */))
const _71a31b39 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _3f8c8018 = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _6896470c = () => interopDefault(import('../pages/sign_in.vue' /* webpackChunkName: "pages/sign_in" */))
const _1010896e = () => interopDefault(import('../pages/sign_out.vue' /* webpackChunkName: "pages/sign_out" */))
const _417c751a = () => interopDefault(import('../pages/sign_up/index.vue' /* webpackChunkName: "pages/sign_up/index" */))
const _ee73d646 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _568dcb06 = () => interopDefault(import('../pages/thanks.vue' /* webpackChunkName: "pages/thanks" */))
const _364f2b60 = () => interopDefault(import('../pages/videographer/index.vue' /* webpackChunkName: "pages/videographer/index" */))
const _0987f330 = () => interopDefault(import('../pages/videographer-online-course/index.vue' /* webpackChunkName: "pages/videographer-online-course/index" */))
const _321a40fb = () => interopDefault(import('../pages/virtual-production/index.vue' /* webpackChunkName: "pages/virtual-production/index" */))
const _f1d29166 = () => interopDefault(import('../pages/lite/motion/index.vue' /* webpackChunkName: "pages/lite/motion/index" */))
const _7cbec6bb = () => interopDefault(import('../pages/motion-graphics/entry/index.vue' /* webpackChunkName: "pages/motion-graphics/entry/index" */))
const _7aaa42f8 = () => interopDefault(import('../pages/motion-graphics/voice.vue' /* webpackChunkName: "pages/motion-graphics/voice" */))
const _0d1a5570 = () => interopDefault(import('../pages/motion-graphics/works.vue' /* webpackChunkName: "pages/motion-graphics/works" */))
const _7bb950ef = () => interopDefault(import('../pages/online_courses/consumer/index.vue' /* webpackChunkName: "pages/online_courses/consumer/index" */))
const _2ab87fbc = () => interopDefault(import('../pages/registration/complete.vue' /* webpackChunkName: "pages/registration/complete" */))
const _f59baa4e = () => interopDefault(import('../pages/registration/entry.vue' /* webpackChunkName: "pages/registration/entry" */))
const _7da733a8 = () => interopDefault(import('../pages/sign_up/complete.vue' /* webpackChunkName: "pages/sign_up/complete" */))
const _0ed28183 = () => interopDefault(import('../pages/videographer/entry/index.vue' /* webpackChunkName: "pages/videographer/entry/index" */))
const _75e05f31 = () => interopDefault(import('../pages/virtual-production/error.vue' /* webpackChunkName: "pages/virtual-production/error" */))
const _468fe9c8 = () => interopDefault(import('../pages/biz/education/smartphone/index.vue' /* webpackChunkName: "pages/biz/education/smartphone/index" */))
const _36bded9d = () => interopDefault(import('../pages/motion-graphics/entry/4.vue' /* webpackChunkName: "pages/motion-graphics/entry/4" */))
const _3316e92a = () => interopDefault(import('../pages/registration/videographer-online/entry.vue' /* webpackChunkName: "pages/registration/videographer-online/entry" */))
const _141679d2 = () => interopDefault(import('../pages/online_courses/consumer/_id.vue' /* webpackChunkName: "pages/online_courses/consumer/_id" */))
const _f63ccbbe = () => interopDefault(import('../pages/virtual-production/curriculums/_curriculum_id/index.vue' /* webpackChunkName: "pages/virtual-production/curriculums/_curriculum_id/index" */))
const _59c403a4 = () => interopDefault(import('../pages/virtual-production/curriculums/_curriculum_id/sections/_section_id/videos/_id.vue' /* webpackChunkName: "pages/virtual-production/curriculums/_curriculum_id/sections/_section_id/videos/_id" */))
const _562a8d12 = () => interopDefault(import('../pages/camps/_camp_id/index.vue' /* webpackChunkName: "pages/camps/_camp_id/index" */))
const _83eff698 = () => interopDefault(import('../pages/online_courses/_id.vue' /* webpackChunkName: "pages/online_courses/_id" */))
const _7012a4c2 = () => interopDefault(import('../pages/camps/_camp_id/_id.vue' /* webpackChunkName: "pages/camps/_camp_id/_id" */))
const _2f741ba5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/billing",
    component: _60bffa11,
    pathToRegexpOptions: {"strict":true},
    name: "billing"
  }, {
    path: "/complete",
    component: _0f63d836,
    pathToRegexpOptions: {"strict":true},
    name: "complete"
  }, {
    path: "/download",
    component: _69ef3005,
    pathToRegexpOptions: {"strict":true},
    name: "download"
  }, {
    path: "/law",
    component: _19768196,
    pathToRegexpOptions: {"strict":true},
    name: "law"
  }, {
    path: "/motion-graphics",
    component: _f1748ad0,
    pathToRegexpOptions: {"strict":true},
    name: "motion-graphics"
  }, {
    path: "/mypage",
    component: _039f91fb,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/online_courses",
    component: _e7fac168,
    pathToRegexpOptions: {"strict":true},
    name: "online_courses"
  }, {
    path: "/registration",
    component: _71a31b39,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/school",
    component: _3f8c8018,
    pathToRegexpOptions: {"strict":true},
    name: "school"
  }, {
    path: "/sign_in",
    component: _6896470c,
    pathToRegexpOptions: {"strict":true},
    name: "sign_in"
  }, {
    path: "/sign_out",
    component: _1010896e,
    pathToRegexpOptions: {"strict":true},
    name: "sign_out"
  }, {
    path: "/sign_up",
    component: _417c751a,
    pathToRegexpOptions: {"strict":true},
    name: "sign_up"
  }, {
    path: "/terms",
    component: _ee73d646,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/thanks",
    component: _568dcb06,
    pathToRegexpOptions: {"strict":true},
    name: "thanks"
  }, {
    path: "/videographer",
    component: _364f2b60,
    pathToRegexpOptions: {"strict":true},
    name: "videographer"
  }, {
    path: "/videographer-online-course",
    component: _0987f330,
    pathToRegexpOptions: {"strict":true},
    name: "videographer-online-course"
  }, {
    path: "/virtual-production",
    component: _321a40fb,
    pathToRegexpOptions: {"strict":true},
    name: "virtual-production"
  }, {
    path: "/lite/motion",
    component: _f1d29166,
    pathToRegexpOptions: {"strict":true},
    name: "lite-motion"
  }, {
    path: "/motion-graphics/entry",
    component: _7cbec6bb,
    pathToRegexpOptions: {"strict":true},
    name: "motion-graphics-entry"
  }, {
    path: "/motion-graphics/voice",
    component: _7aaa42f8,
    pathToRegexpOptions: {"strict":true},
    name: "motion-graphics-voice"
  }, {
    path: "/motion-graphics/works",
    component: _0d1a5570,
    pathToRegexpOptions: {"strict":true},
    name: "motion-graphics-works"
  }, {
    path: "/online_courses/consumer",
    component: _7bb950ef,
    pathToRegexpOptions: {"strict":true},
    name: "online_courses-consumer"
  }, {
    path: "/registration/complete",
    component: _2ab87fbc,
    pathToRegexpOptions: {"strict":true},
    name: "registration-complete"
  }, {
    path: "/registration/entry",
    component: _f59baa4e,
    pathToRegexpOptions: {"strict":true},
    name: "registration-entry"
  }, {
    path: "/sign_up/complete",
    component: _7da733a8,
    pathToRegexpOptions: {"strict":true},
    name: "sign_up-complete"
  }, {
    path: "/videographer/entry",
    component: _0ed28183,
    pathToRegexpOptions: {"strict":true},
    name: "videographer-entry"
  }, {
    path: "/virtual-production/error",
    component: _75e05f31,
    pathToRegexpOptions: {"strict":true},
    name: "virtual-production-error"
  }, {
    path: "/biz/education/smartphone",
    component: _468fe9c8,
    pathToRegexpOptions: {"strict":true},
    name: "biz-education-smartphone"
  }, {
    path: "/motion-graphics/entry/4",
    component: _36bded9d,
    pathToRegexpOptions: {"strict":true},
    name: "motion-graphics-entry-4"
  }, {
    path: "/registration/videographer-online/entry",
    component: _3316e92a,
    pathToRegexpOptions: {"strict":true},
    name: "registration-videographer-online-entry"
  }, {
    path: "/online_courses/consumer/:id",
    component: _141679d2,
    pathToRegexpOptions: {"strict":true},
    name: "online_courses-consumer-id"
  }, {
    path: "/virtual-production/curriculums/:curriculum_id",
    component: _f63ccbbe,
    pathToRegexpOptions: {"strict":true},
    name: "virtual-production-curriculums-curriculum_id"
  }, {
    path: "/virtual-production/curriculums/:curriculum_id/sections/:section_id/videos/:id?",
    component: _59c403a4,
    pathToRegexpOptions: {"strict":true},
    name: "virtual-production-curriculums-curriculum_id-sections-section_id-videos-id"
  }, {
    path: "/camps/:camp_id",
    component: _562a8d12,
    pathToRegexpOptions: {"strict":true},
    name: "camps-camp_id"
  }, {
    path: "/online_courses/:id",
    component: _83eff698,
    pathToRegexpOptions: {"strict":true},
    name: "online_courses-id"
  }, {
    path: "/camps/:camp_id?/:id",
    component: _7012a4c2,
    pathToRegexpOptions: {"strict":true},
    name: "camps-camp_id-id"
  }, {
    path: "/",
    component: _2f741ba5,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/billing/*",
    component: _60bffa11,
    name: "billing_redirect"
  }, {
    path: "/school/*",
    component: _3f8c8018,
    name: "school_redirect"
  }, {
    path: "/mypage/*",
    component: _039f91fb,
    name: "mypage_redirect"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
